<template>
  <div>
    <!-- header card -->
    <b-overlay
      :show="showLoading"
      variant="dark"
      :opacity="0.25"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <!-- Code Field -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.transactionsCashAdvanceRequest.singular.code')"
              label-for="code"
            >
              <b-form-input
                id="code"
                size="sm"
                v-model="form.code"
                :disabled="actions.isPreview"
                :placeholder="!actions.isPreview ? $t('apps.transactions.cashadvance.placeholder.code') : null"
              />
            </b-form-group>
          </b-col>
          <!-- Requestor (employee) Field -->
          <b-col cols="12" md="6">
            <b-form-group
              :label="$t('apps.transactions.cashadvance.singular.requestor')"
              label-for="employee-id"
            >
              <validation-provider
                #default="{ errors }"
                name="employee"
                rules="required"
              >
                <v-select
                  id="employee-id"
                  v-model="form.employeeId"
                  :options="LOV.contact"
                  :reduce="field => field.id"
                  :getOptionLabel="option => `${option.label}`"
                  class="select-size-sm"
                  @option:selected="handleChangeEmployee"
                  :disabled="actions.isPreview"
                  :filterable="false"
                  @search="value => getContact(value)"
                  :loading="contactLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Type Code Field -->
          <!-- <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.transactionsCashAdvanceRequest.singular.type')"
              label-for="type-code"
            >
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <v-select
                  id="type-code"
                  v-model="form.typeCode"
                  label="name"
                  :options="LOV.purposeTypes"
                  :reduce="field => field.code"
                  @option:selected="handleChangePurposeType"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- Work Unit -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('globalSingular.workUnit')"
              label-for="work-unit">
              <validation-provider
                #default="{ errors }"
                name="work-unit"
                rules="required"
              >
                <v-select
                  id="work-unit"
                  v-model="form.workUnitId"
                  :options="LOV.workUnits"
                  :reduce="field => field.id"
                  label="name"
                  @option:selected="handleChangeWorkUnit"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Reference Code di remaks mau dipindahin ke approval -->
          <!-- <b-col cols="12" md="3">
            <b-form-group
            :label="$t('globalSingular.referenceCode')"
            label-for="referenceCode"
            >
              <b-form-input
                id="referenceCode"
                size="sm"
                v-model="form.referenceCode"
                :placeholder="!actions.isPreview ? $t('globalSingular.referenceCode') : null"
                :disabled="actions.isPreview"
              />
            </b-form-group>
          </b-col> -->
          <!-- Date Field -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.transactions.cashadvance.singular.date')"
              label-for="date"
            >
              <validation-provider
                #default="{ errors }"
                name="Request Date"
                rules="required"
              >
                <flatPickr
                id="date"
                size="sm"
                :config="dateFormat"
                v-model="form.date"
                :state="errors.length > 0 ? false:null"
                :disabled="actions.isPreview"
                :placeholder="!actions.isPreview ? $t('apps.transactions.cashadvance.singular.date') : null"
                class="form-control form-control-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Purpose Date Field -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.transactions.cashadvance.singular.purposeDate')"
              label-for="purpose-date"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <flat-pickr
                  id="purpose-date"
                  :config="dateFormat"
                  v-model="form.purposeDate"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="!actions.isPreview ? $t('apps.transactions.cashadvance.singular.purposeDate') : null"
                  :disabled="actions.isPreview"
                  class="form-control form-control-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Tag Field -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.transactionsCashbank.singular.tags')"
              label-for="tags">
              <v-select
                v-model="form.tags"
                id="tags"
                label="name"
                multiple
                :options="LOV.tags"
                :reduce="field => field.id"
                :searchable="!actions.isPreview"
                :selectable="option => !actions.isPreview"
              />
            </b-form-group>
          </b-col>
          <!-- PO/PR Field -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.transactionsCashbank.singular.po')"
              label-for="po">

              <v-select
                  id="employee-id"
                  v-model="form.no_po_pr"
                  :options="LOV.noPo"
                  :reduce="field => field.id"
                  :getOptionLabel="option => `${option.code}`"
                  class="select-size-sm"
                  :disabled="actions.isPreview"
                />
            </b-form-group>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12" md="8">
            <b-form-group
              :label="$t('apps.transactionsCashbank.singular.notes')"
              label-for="notes">
              <b-form-textarea
                id="notes"
                size="sm"
                v-model="form.description"
                rows="1"
                :placeholder="!actions.isPreview ? $t('apps.transactions.cashadvance.placeholder.description') : null"
                :disabled="actions.isPreview"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <b-overlay
      :show="showLoading"
      variant="dark"
      :opacity="0.25"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-col cols="12">
            <validation-observer ref="formRef">
                <b-form @submit.prevent>
                  <b-table
                  id="refFormTable"
                  ref="refFormTable"
                  fixed
                  no-provider-paging
                  no-provider-filtering
                  :fields="tableColumns"
                  :items="form.details"
                  primary-key="id"
                  show-empty
                  class="position-relative table-sm"
                >
                  <!-- Custom Header Column -->
                  <template #head(actions)>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="mx-auto"
                    />
                  </template>

                  <!-- Styling width -->
                  <!-- Column: Account -->
                  <template #cell(accountId)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="account"
                        rules="required"
                        :vid="`detail-${index}`"
                      >
                        <v-select
                        id="account"
                        v-model="form.details[index].accountId"
                        :options="LOV.accounts"
                        :reduce="field => field.id"
                        label="label"
                        @option:selected="activityChange(index,LOV.accounts,form.details[index].accountId)"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <!-- Column: Description -->
                  <template #cell(description)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="description"
                        :vid="`detail-${index}`"
                      >
                        <b-form-input
                          size="sm"
                          v-model="form.details[index].description"
                          :state="errors.length > 0 ? false:null"
                          :disabled="actions.isPreview"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <!-- Column: Request Amount -->
                  <template #cell(requestAmount)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="requestamount"
                        rules="required"
                        :vid="`detail-${index}`"
                      >
                        <cleave
                          :key="index"
                          v-model.number="form.details[index].requestAmount"
                          :state="errors.length > 0 ? false:null"
                          class="text-right form-control form-control-sm"
                          :options="numeric"
                          :disabled="actions.isPreview"
                          placeholder="10,000"
                          @blur="sumRequestAmount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="{ index }">
                    <b-button
                      variant="flat-danger"
                      class="btn-icon invoice-edit-input"
                      :disabled="form.details.length <= 1"
                      @click="removeRow(index)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </template>

                  <!-- Costum Footer -->
                  <template v-slot:custom-foot="{}">
                    <b-tr>
                      <b-td colspan="4" class="p-0">
                        <b-button
                          v-if="!actions.isPreview"
                          variant="outline-primary"
                          block
                          @click="addRow"
                          size="sm"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                          {{ $t('globalActions.addMore') }}
                        </b-button>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="text-right" colspan="2">
                        <div class="mb-0 h4 font-weight-bolder">Total</div>
                      </b-td>
                      <!-- <b-td class="text-right">
                        <div class="mb-0 h4 font-weight-bolder">{{ form.requestAmount }}</div>
                      </b-td> -->
                      <b-td class="text-right">
                        <div class="mb-0 h4 font-weight-bolder">
                          <cleave
                            v-model.number="form.requestAmount"
                            class="text-right form-control d-none"
                            :options="numeric"
                          />
                          {{ formatCurrency(totalRequestAmount) }}
                        </div>
                      </b-td>
                    </b-tr>
                  </template>
                </b-table>


                <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                  <b-button
                    class="mb-sm-1 mb-md-0"
                    :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                    :to="{ name: 'apps-transactions-cash-advance-request-list'}"
                  >
                    <feather-icon
                      icon="ArrowLeftIcon"
                    />
                    {{ $t('globalActions.backToList') }}
                  </b-button>

                  <b-button
                    v-if="actions.isPreview"
                    class=" mb-sm-1 mb-md-0"
                    variant="outline-danger"
                    @click="handleDelete"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                    {{ $t('globalActions.delete') }}
                  </b-button>

                  <!-- <b-button
                    v-if="actions.isPreview"
                    class=" mb-sm-1 mb-md-0"
                    variant="success"
                    :to="{ name: 'apps-transactions-cash-advance-request-approval-detail', params: { id: paramsId }}"
                  >
                    <feather-icon
                      icon="CheckIcon"
                    />
                    {{ $t('globalActions.approve') }}
                  </b-button> -->

                  <b-button
                    v-if="!actions.isPreview"
                    variant="primary"
                    type="submit"
                    @click="handleSubmit"
                  >
                    <feather-icon
                      icon="SaveIcon"
                    />
                    {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
                  </b-button>

                  <b-button
                    v-if="!isApproved"
                    variant="outline-primary"
                    @click="changeToEdit"
                  >
                    <feather-icon
                      icon="EditIcon"
                    />
                    {{ $t('globalActions.changeToEdit') }}
                  </b-button>

                <b-button
                    v-if="!print"
                    variant="info"
                    type="submit"
                    @click="downloadPDF()"
                  >
                    <feather-icon
                      icon="SaveIcon"
                    />
                      Print
                  </b-button>

                </div>

                </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import {ref, onMounted } from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import {formatCurrency, formatDatepicker} from '@/utils/formatter'
import { required } from '@validations'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useFormResource from '@/comp-functions/useFormResource'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'
import { useToast } from 'vue-toastification/composition'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import jsPDF from 'jspdf' 


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    flatPickr,
    vSelect
  },
  setup () {
    const { $get } = useHttp()

    const $toast = useToast()
    const {
      paramsId,
      formRef,
      actions,
      changeToEdit,
      get,
      customStore,
      destroy,
      show,
      store
    } = useFormResource({
      url: 'transaction/cash-advance/request',
      localeContextPath: 'apps.transactionsCashAdvanceRequest.singular.cashAdvanceRequest',
      redirectPathName: 'apps-transactions-cash-advance-request'
    })

    const { $t } = useLocalization()

    const limitBy = ref(null)

    const form = ref({
      code: '',
      date: formatDatepicker(Date.now()),
      purposeDate: formatDatepicker(Date.now()),
      employeeId: '',
      description: '',
      no_po_pr: '',
      typeCode: 'CAGN',
      workUnitId: '',
      referenceCode: '',
      requestAmount: 0,
      approvedDate: formatDatepicker(Date.now()),
      details: [{ accountId: `${$t('apps.transactionsJournal.singular.account')}`, description: '', requestAmount: null }]
    })

    const tableColumns = ref([])
    const showLoading = ref(false)

    const print = ref(true)
    const dataPrint = ref()


    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const LOV = ref({
      workUnits: [],
      accounts: [],
      contact: [],
      codes: [],
      tags: [],
      purposeTypes: [],
      noPo: []
    })

    const searchDelay = ref(null)

    const getWorkUnits = async () => {
      LOV.value.workUnits = await get({ url: 'value/work-unit' })
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const contactLoading = ref(false)
    const getContact = async (search = '') => {
      contactLoading.value = true
      clearTimeout(searchDelay.value)
      searchDelay.value = await setTimeout(async () => {
        LOV.value.contact = search.length > 1
          ? await get({ url: `value/contact/employee?search=${search}` })
          : await get({ url: `value/contact/employee?limit=100` })
      }, 500)
      contactLoading.value = false
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts/activity'})
    }

    const getPO = async () => {
      LOV.value.noPo = await get({ url: 'value/getPo'})
    }

    const gePurposeTypes = async () => {
      LOV.value.purposeTypes = await get({ url: 'value/system-codes?module=CASH_ADVANCE_PURPOSE'})
    }

    const getActiveAdvanceLimit = async () => {
      const data = await get({ url: 'transaction/cash-advance/request/active-limit-type'})
      limitBy.value = data
    }

    const activityChange = async (index, option, selected) => {
        option.map(field => {
            if(field.id == selected){
                form.value.details[index].description = field.description
            }
        })
    }


    const totalRequestAmount = ref(0)
    const totalApprovedAmount = ref(0)

    const sumRequestAmount = () => {
      totalRequestAmount.value = form.value.details.reduce((accumulator, {requestAmount}) => accumulator + requestAmount, 0)
      form.value.requestAmount = totalRequestAmount.value
    }

    let isApproved = ref()

    // will get data to show the preview
    const getDataPreview = async () => {
      const data                 = await show()
      form.value.code            = data.code
      form.value.date            = data.date
      form.value.purposeDate     = data.purpose_date
      form.value.employeeId      = data.employee_id
      form.value.description     = data.description
      form.value.no_po_pr        = Number(data.no_po_pr)
      form.value.typeCode        = data.type_code
      form.value.workUnitId      = data.work_unit_id
      form.value.requestAmount   = data.request_amount
      form.value.approvedAmount  = data.approved_amount
      isApproved.value           = data.is_approved
        if (isApproved.value){
          print.value = false
        }else{
            print.value = false
        }
      form.value.details         = data.details.map((detail) => {
        return {
          id: detail.id,
          accountId: detail.account_id,
          description: detail.description,
          requestAmount: detail.request_amount,
          approvedAmount: detail.approved_amount
        }
      })

      totalRequestAmount.value = form.value.requestAmount
      totalApprovedAmount.value = formatCurrency(data.approved_amount)
    }

    // Add row details
    const addRow = () => {
      form.value.details.push({ accountId: '', description: '', requestAmount: null})
    }
    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
      sumRequestAmount()
    }

    const handleChangePurposeType = async val => {


      if (val && form.value.employeeId && limitBy.value.limit_type === 'TYPE') {
        const detailLimit = limitBy.value.details.find(limit => limit.code === val.code)
        const data = await get({ url: `transaction/cash-advance/request/check-limit-type/${form.value.employeeId}/${val.code}`})
        if (!data.is_valid) {
          const amount = parseFloat(detailLimit.amount)
          let message = ''
          if (detailLimit.is_refund_first) {
            message = `This employee with purpose type ${val.name} has not made the refund in the previous cash advance transaction!`
          }
          if (detailLimit.is_realization_first) {
            message = `This employee with purpose type ${val.name} has not made the realization in the previous cash advance transaction!`
          }
          if (detailLimit.balance_must_zero) {
            message = `This employee with purpose type ${val.name} still have outstanding amount in the previous cash advance transaction!`
          }
          if (amount > 0) {
            message = `This employee with purpose type ${val.name} still have outstanding amount more than limit (${formatCurrency(amount)}) in the previous cash advance transaction!`
          }
          form.value.employeeId = null
          form.value.typeCode = null
          $toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: message
            }
          })
        }
      }
    }

    const handleChangeEmployee = async val => {
      if (val && limitBy.value.limit_type === 'ALL') {
        const data = await get({ url: `transaction/cash-advance/request/check-limit-all/${val.id}`})
        if (data.is_valid) {
          const amount = parseFloat(limitBy.value.details[0].amount)
          let message = ''
          if (limitBy.value.details[0].is_refund_first) {
            message = 'This employee has not made the refund in the previous cash advance transaction!'
          }
          if (limitBy.value.details[0].is_realisation_first) {
            message = 'This employee has not made the realization in the previous cash advance transaction!'
          }
          if (limitBy.value.details[0].balance_must_zero) {
            message = 'This employee still have outstanding amount in the previous cash advance transaction!'
          }
          if (amount > 0) {
            message = `This employee still have outstanding amount more than limit (${formatCurrency(amount)}) in the previous cash advance transaction!`
          }
          if(message != ''){
          form.value.employeeId = null

            $toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: message
              }
            })
          }
         
        }
      }
    }

    const handleChangeWorkUnit = async val => {
      if (val && form.value.employeeId && limitBy.value.limit_type === 'WORKUNIT') {
        const detailLimit = limitBy.value.details.find(limit => limit.code === val.code)
        const data = await get({ url: `transaction/cash-advance/request/check-limit-work-unit/${form.value.employeeId}/${val.code}`})
        if (!data.is_valid) {
          const amount = parseFloat(detailLimit.amount)
          let message = ''
          if (detailLimit.is_refund_first) {
            message = `This employee with purpose type ${val.name} has not made the refund in the previous cash advance transaction!`
          }
          if (detailLimit.is_realization_first) {
            message = `This employee with purpose type ${val.name} has not made the realization in the previous cash advance transaction!`
          }
          if (detailLimit.balance_must_zero) {
            message = `This employee with purpose type ${val.name} still have outstanding amount in the previous cash advance transaction!`
          }
          if (amount > 0) {
            message = `This employee with purpose type ${val.name} still have outstanding amount more than limit (${formatCurrency(amount)}) in the previous cash advance transaction!`
          }
          form.value.employeeId = null
          form.value.workUnitId = null
          $toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: message
            }
          })
        }
      }
    }

    onMounted(async () => {
      showLoading.value = true
      await getActiveAdvanceLimit()
      // watchByLimit()
      await gePurposeTypes()
      await getWorkUnits()
      await getContact()
      await getTags()
      await getPO()

      if (paramsId) {
        actions.value.isPreview = true
        await getDataPreview()
      }

      await getAccounts()
      tableColumns.value = [
        { key: 'accountId', label: $t('apps.transactionsCashAdvanceRequest.singular.activity'), width: 'auto', thClass: 'pl-0', tdClass: 'py-1 align-top pl-0' },
        { key: 'description', label: $t('apps.transactionsCashAdvanceRequest.singular.notes'), width: 'auto', thClass: '', tdClass: 'py-1 align-top'},
        { key: 'requestAmount', label: $t('apps.transactionsCashAdvanceRequest.singular.requestamount'), width: '24%', thClass: 'text-right width-25-per', tdClass: 'py-1 align-top text-right'},
        { key: 'actions', width: '12%', thClass: 'text-center width-10-per  pr-0', tdClass: 'py-1 align-top text-center pr-0'}
      ]
      showLoading.value = false
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    const getDataPreviewPrint = async (id) => {
      const { data } =  await $get({
        url: `transaction/cash-advance/request/${id}`
      })
      dataPrint.value = data
    }

    const downloadPDF = async() =>{
      await getDataPreviewPrint(paramsId)
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: 'a5'
      });

      // advance report 
      doc.setFontSize(15).setFont('','bold')
      if (dataPrint.value.po == null) {
        doc.text("CASH ADVANCE REQUEST", 70, 10)
      } else {
        doc.text("CASH ADVANCE REPORT", 70, 10)
      }
      doc.line(70, 11, 138, 11)

      let xPos = 5
      let yPos = 16.5

      let xReactPos = 3
      let yReactPos = 12
      doc.setFontSize(11).setFont('','bold')

      // logo
      let imageData = `${window.location.origin}/images/logo-pdf.png`
      doc.addImage(imageData, 'PNG', 4, 3, 45, 12);

      xReactPos += 10
      yReactPos += 0

      // kotak pertama
      doc.rect(xReactPos - 10, yReactPos + 10, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 10, 101.5, 7)
      doc.text("Employee Request  :  " + `${dataPrint.value.employee.code} - ${dataPrint.value.employee.brand}`, xPos, yPos + 10)
      doc.text("Date Request            :  " + `${dataPrint.value.date}`, xPos + 101, yPos + 10)

      // kotak kedua 
      doc.rect(xReactPos - 10, yReactPos + 17, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 17, 101.5, 7)
      doc.text("Branch                     :  " + `${dataPrint.value.getOffice.brand}`, xPos , yPos + 17)
      doc.text("Date Approved         :  " + `${dataPrint.value.approved_date}`, xPos + 101, yPos + 17)
      
      // kotak ketiga
      doc.rect(xReactPos - 10, yReactPos + 24, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 24, 101.5, 7)
      if (dataPrint.value.no_po_pr == null) {
        dataPrint.value.no_po_pr = '-'
      }

      if (dataPrint.value.po == null) {
        doc.text("PO/PR                      :  " + `${dataPrint.value.no_po_pr}`, xPos, yPos + 24)
      } else {
        doc.text("PO/PR                      :  " + `${dataPrint.value.po.code}`, xPos, yPos + 24)
      }
      doc.text("Request Amount      :  " + `${formatCurrency(parseFloat(dataPrint.value.request_amount))}`, xPos + 101, yPos + 24)
      
      // kotak keempat
      doc.rect(xReactPos - 10, yReactPos + 31, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 31, 101.5, 7)
      doc.text("Code Request          :  " + `${dataPrint.value.code}`, xPos , yPos + 31)
      if (dataPrint.value.approved_amount != null) {
        doc.text("Approved Amount   :  " + `${formatCurrency(parseFloat(dataPrint.value.approved_amount))}`, xPos + 101, yPos + 31)
      } else {
        doc.text("Approved Amount   :  " + " ", xPos + 101, yPos + 31)
      }

      // kotak kelima
      doc.rect(xReactPos - 10, yReactPos + 38, 203, 7)
      // doc.rect(xReactPos + 91.5, yReactPos + 38, 101.5, 7)
      doc.text("Notes                        :  " + `${dataPrint.value.description}`, xPos, yPos + 38)
      // doc.text("Request Amount      :  " + `${formatCurrency(parseFloat(dataPrint.value.request_amount))}`, xPos + 101, yPos + 38)

      /* garis bawah detail of invoice */  
      doc.line(3, 62, 206, 62)
      doc.line(3, 67, 206, 67)

      // garis lurus pojok kiri
      doc.line(3, 62, 3, 120)
      /* no */ 
      doc.text("No", xPos - 1, yPos + 49)
      doc.line(10, 62, 10, 120)
      /* Description */ 
      doc.text("Activity", xPos + 60, yPos + 49)
      doc.line(132, 62, 132, 120) 
      /* Invoice Number */
      doc.text("Request Amount", xPos + 131, yPos + 49)
      doc.line(168, 62, 168, 120) 
      /* Amount */
      doc.text("Approved Amount ", xPos + 166, yPos + 49)
      doc.line(206, 62, 206, 120) 
      /* Garis paling bawah */
      doc.line(3, 120, 206, 120)

      doc.setFontSize(10).setFont('','bold')

      doc.text("Request By, ", xPos , yPos + 108)

      doc.text("Nama    : ", xPos , yPos + 115)
      doc.line(20, 132, 70, 132)
      doc.text("Jabatan : ", xPos , yPos + 121)
      doc.line(20, 138, 70, 138)

      doc.text("Approve By, ", xPos + 160 , yPos + 108)

      doc.text("Nama    : ", xPos + 140, yPos + 115)
      doc.line(160, 132, 205, 132)

      doc.text("Jabatan : ", xPos +140 , yPos + 121)
      doc.line(160, 138, 205, 138)


      let looping = dataPrint.value.detailsActivity
      let baris = 3
      //  let totalAmount = 0
      for (let index = 0; index < looping.length; index++) {
        baris = baris + 3
        let total = 43 + baris;
        // totalAmount = totalAmount + dataPrint.value.details[index].amount
        doc.text(`${index + 1}.`, xPos , (yPos + total + baris))
        doc.text(`${dataPrint.value.detailsActivity[index].activity.name}`, xPos + 6, (yPos + total + baris))
        doc.text("Rp.                 " + `${formatCurrency(parseFloat(dataPrint.value.detailsActivity[index].request_amount), false)}`, xPos + 127.5, (yPos + total + baris))
        if (dataPrint.value.approved_amount != null) {
          doc.text("Rp.                   " + `${formatCurrency(parseFloat(dataPrint.value.detailsActivity[index].approved_amount), false)}`, xPos + 163.5, (yPos + total + baris))
        } else {
          doc.text(" " + " ", xPos + 147, (yPos + total + baris))
        }
        // doc.text("Rp.                         " + `${formatCurrency(parseFloat(dataPrint.value.detailsActivity[index].approved_amount), false)}`, xPos + 147, (yPos + total + baris))
      }      
      doc.output('dataurlnewwindow');
      // await doc.save(`${dataPrint.value.code}.pdf`, {returnPromise: true})
    }

    return {
      limitBy,
      handleChangeEmployee,
      handleChangePurposeType,
      handleChangeWorkUnit,
      form,
      pushTags,
      activityChange,
      sumRequestAmount,
      totalRequestAmount,
      numeric,
      paramsId,
      formRef,
      get,
      customStore,
      actions,
      changeToEdit,
      dateFormat,
      LOV,
      tableColumns,
      totalApprovedAmount,
      addRow,
      destroy,
      store,
      removeRow,
      required,
      print,
      formatCurrency,
      isApproved,
      downloadPDF,
      showLoading,
      option: [
        {value: 'CAOP', text: 'Oprational'},
        {value: 'CAPR', text: 'Personal'}
      ],
      getContact,
      contactLoading,
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
